<template>
  <div :class="isPc ? 'course_header' : 'course_sp_header'">
    <div class="sourseList_bg_content" :style="{'background-image': 'url('+viewState.sourseListBG+')'}">
      <img v-if="isPc" class="sourseList_bg_line" :src="viewState.sourseListBGLine" alt="">
      <div class="sourseList_title">
        <div class="sourseList_txt">
          <div class="item_txt">
            <h1>講座一覧</h1>
            <p>ジッセン!DXではITツールの基礎操作から業務に役立つスキルまで体系的な多種多様な講座を受講できます。</p>
          </div>
          <div :class="isPc ? 'item_share' : 'item_sp_share'">
            <shareBtn class="share_box"></shareBtn>
          </div>
        </div>
        <img class="sourseList_bg_book" :src="viewState.sourseListBGBook" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import sourseListBG from '@/assets/img/sourseList_bg.png'
import sourseListBGBook from '@/assets/img/sourseList_bg_book.png'
import sourseListBGLine from '@/assets/img/sourseList_bg_line.png'
import sourseListSPBG from '@/assets/img/sourseList_sp_bg.png'
import sourseListSPBGBook from '@/assets/img/sourseList_sp_bg_book.png'
import shareBtn from '@/components/CommonShare/CommonShare.vue'
import { defineComponent, onMounted, reactive, watch } from "vue";


export default defineComponent({
  name: "courseHeader",
  props: ['isPc'],
  components: {
    shareBtn
  },
  setup(props) {
    const viewState = reactive({
      sourseListBG: '',
      sourseListBGBook: '',
      sourseListBGLine: sourseListBGLine,
    });

    // method
    const selImg = (bol) => {
      viewState.sourseListBG = bol ? sourseListBG : sourseListSPBG
      viewState.sourseListBGBook = bol ? sourseListBGBook : sourseListSPBGBook
    };

    onMounted(async () => {
      selImg(props['isPc']);
    });

    // watch
    watch(
      () => props['isPc'],
      (value) => {
        selImg(value);
      }
    )

    return {
      viewState,
      // method
      selImg
    }
  },
});
</script>

<style lang="scss" scoped>
//PC style
.course_header {
  max-width: 1366px;
  margin-bottom: 65px;

  .sourseList_bg_content {
    background-repeat: no-repeat;
    background-position: left 50px top 0;
    position: relative;
    padding: 0 50px;

    .sourseList_bg_line {
      width: 100%;
      height: 804px;
    }

    .sourseList_title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 50px;

      .sourseList_txt {
        width: 100%;
        display: flex;
        height: 275px;
        justify-content: space-between;
        margin-bottom: 30px;

        .item_txt {
          margin-left: 65px;

          > h1 {
            margin: 14.8% 0 22px 0;
            text-align: left;
            font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
            font-weight: 700;
            line-height: 70px;
            height: 70px;
            font-size: 40px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
          }

          > p {
            margin: 0;
            text-align: left;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            height: 30px;
            letter-spacing: 0.1em;
            color: $dark-grey;
          }
        }

        .item_share {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          margin-right: 5.1%;
          width: 83px;
        }
      }

      .sourseList_bg_book {
        width: 100%;
      }
    }
  }
}

//SP style
.course_sp_header {
  margin-bottom: 60px;
  overflow: hidden;

  .sourseList_bg_content {
    background-repeat: no-repeat;
    background-position: top right;
    background-size: calc(100% - 20px) 362px;

    .sourseList_title {
      .sourseList_txt {
        padding: 80px 0 0 50px;

        .item_txt {
          padding-right: 50px;
          h1 {
            font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
            font-weight: 700;
            font-size: 30px;
            height: 50px;
            line-height: 50px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
            margin: 0;
          }

          p {
            width: 100%;
            text-align: left;
            height: 75px;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.1em;
            color: $dark-grey;
            margin: 24px 0 0 0;
            display: flex;
            flex-direction: column;
          }
        }

        .item_sp_share {
          display: flex;
          height: 24px;
          justify-content: flex-end;
          align-items: center;
          margin: 12px 21px 18px 0;

          .share_box {
            ::v-deep(.common-share-container) {
              align-items: center;

              .common-share-icon {
                width: 18px !important;
                height: 17.1px !important;
                margin-left: 8px !important;
              }

              .common-share-icon-active {
                width: 18px !important;
                height: 17.1px !important;
                margin-left: 8px !important;
              }
            }
          }
        }
      }

      .sourseList_bg_book {
        padding-left: 20px;
      }
    }
  }
}
</style>
