<template>
  <div class="custom-course-lectures">
    <commonTitle :title="customCourseLecture.custom_course_name"></commonTitle>

    <div class="flex-video">
      <template
        v-for="(item, index) in viewState.lectures"
        :key="index"
      >
        <div class="flex-video-item">
          <commonVideoItem
            :imgIndex="index % 4"
            :video="item"
            :isEnterprise="isEnterprise"
            @bookmark="bookmark"
            style="margin-top: 40px"
          ></commonVideoItem>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import commonVideoItem from "@/components/CommonVideo/CommonVideoItem.vue";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import { defineComponent, computed, reactive } from "vue";
import { useStore } from 'vuex'

export default defineComponent({
  name: "customCourseLecture",
  components: {
    commonVideoItem,
    commonTitle
  },
  props: ['customCourseLecture'],
  setup(props, context) {
    const store = useStore();
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const viewState = reactive({
      lectures: props.customCourseLecture.lectures
    });

    // method
    const bookmark = (lectureKey, isFavorite) => {
      context.emit('bookmark', props.customCourseLecture.custom_course_key, lectureKey, isFavorite);
    }

    return {
      viewState,
      isEnterprise,

      // method
      bookmark,
    }
  },
});
</script>

<style lang="scss" scoped>
.custom-course-lectures {
  margin-top: 65px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1349px;
  @include mq(sm) {
    margin-top: 60px;
  }

  .common-title {
    max-width: 1366px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 10px;
      height: 62px;
      top: 0px;
      background: $primary;

      @include mq(sm) {
        height: 38px;
        top: 40px;
      }
    }
    ::v-deep(.common-title-area) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 65px;

      @include mq(sm) {
        height: unset;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-left: 30px;
        height: 78px;
      }

      .common-titles-title {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
        margin-right: 40px;

        @include mq(sm) {
          margin-top: 0px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 0.08em;
          color: $black;
          text-align: left;
          margin-right: 50px;
          margin-top: 10px;
        }
      }
    }
  }

  .flex-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 65px;
    margin-right: 65px;
    width: 100%;

    @include mq(sm) {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 30px;
      width: 275px;
      height: auto;
      .flex-video-items {
        height: 418px;
      }
    }

    .flex-video-item {
      margin-right: 28px;
    }
  }
}
</style>
