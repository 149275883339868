<template>
  <div class="sourseList_tab_content">
    <div class="tab_background">
      <template v-for="(item, index) in jobRoles" :key="index">
        <div class="tab_item"
          @click="selTab(item.id)"
        >
          <p>{{ item.name }}</p>
          <p>{{ `(${item.lectures_count})` }}</p>
        </div>
      </template>
    </div>
    <div class="job_roles_line"></div>
    <icon name="bg-dot-circle" class="imgBg" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "courseTab",
  props: ['jobRoles'],
  setup() {
    // method
    const selTab = (id) => {
      document.getElementById(`jobrole-${id}`).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    return {
      // method
      selTab
    }
  },
});
</script>

<style lang="scss" scoped>
.sourseList_tab_content {
  width: 100%;
  padding: 0 50px;
  position: relative;
  .tab_item {
    width: 253px;
    height: 80px;
    color: $dark-grey;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > p {
      margin: 1px 0;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.08em;
    }
  }
  .imgBg {
    position: absolute;
    width: 620px;
    height: 620px;
    bottom: -310px;
    left: -310px;
    z-index: -1;
  }
  .job_roles_line {
    display: flex;
    max-width: 1266px;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    backdrop-filter: blur(10px);
    align-items: center;
    border-bottom: 4px solid $secondary;
  }
}

.tab_background {
  background: linear-gradient(0deg, rgba(54, 131, 245, 0.02), rgba(54, 131, 245, 0.02)), $white;
  display: flex;
  flex-wrap: wrap;
}

.tab_active {
  background: $primary!important;
  color: #fff!important;
  border: 0.5px solid rgba(54, 131, 245, 0.5) !important;
  box-shadow: 0px 20px 30px rgba(54, 130, 243, 0.25) !important;
}
</style>
