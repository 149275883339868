<template>
  <div>
    <div class="sourse_sp_tab_content">
      <div class="sp_tab_title">
        <p>ジョブロール一覧</p>
      </div>
      <div class="tab_btn" @click="tabBtn">
        <img :src="menuIcon" alt="">
      </div>
    </div>
    <div v-if="viewState.isShowDialog" class="tab_dialog">
      <div class="list_content">
        <div class="list_title">
          <div class="list_txt">
            <p>List</p>
            <span>リスト</span>
          </div>
          <div class="list_close_icon" @click="tabBtn">
            <img :src="closeIcon" alt="">
          </div>
        </div>
        <el-scrollbar class="items_scroll" always height="545px">
          <template v-for="(item, index) in jobRoles" :key="index">
            <div
              class="items" :class="item.id === viewState.activeJobRoleId ? 'items_active' : ''"
              @click="selTab(item.id)"
            >
              <p>{{ item.name }}</p>
              <span>{{ `(${item.lectures_count})` }}</span>
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import menuIcon from '@/assets/img/menuIcon.png'
import closeIcon from '@/assets/img/closeIcon.png'
import {ElScrollbar} from "element-plus"
import { defineComponent, reactive, watch } from "vue";

export default defineComponent({
  name: "courseSPTab",
  props: ['jobRoles'],
  components: {ElScrollbar},
  setup() {
    const viewState = reactive({
      isShowDialog: false,
      activeJobRoleId: 0,
    });

    // method
    const tabBtn = () => {
      viewState.isShowDialog = !viewState.isShowDialog
    };
    const selTab = (id) => {
      viewState.isShowDialog = id;
      tabBtn();
      document.getElementById(`jobrole-${id}`).scrollIntoView();
    }

    // watch
    watch(
      () => viewState.isShowDialog,
      (value) => {
        if (value) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      }
    )

    return {
      viewState,
      menuIcon,
      closeIcon,

      // method
      tabBtn,
      selTab,
    }
  },
});
</script>

<style lang="scss" scoped>
.sourse_sp_tab_content {
  margin: 0 20px;
  display: flex;
  border-bottom: 4px solid $secondary;

  .sp_tab_title {
    flex: 1;
    display: flex;
    align-items: center;
    background: $white;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    p {
      margin: 0 0 0 20px;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $primary;
    }

    span {
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $black;
      margin-left: 10px;
    }
  }

  .tab_btn {
    width: 60px;
    height: 60px;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tab_dialog {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(54, 131, 245, 0.1);
  backdrop-filter: blur(5px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .list_content {
    width: 315px;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    background-color: rgba(255,255,255,0.9);

    .list_title {
      display: flex;
      border-bottom: 1px solid $black;
      height: 60px;
      width: 100%;
      background-color: #fff;

      .list_txt {
        flex: 1;
        display: flex;
        align-items: center;

        p {
          margin: 0 0 0 20px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $black;
        }

        span {
          font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $black;
          opacity: 0.5;
          margin-left: 10px;
          margin-top: 2px;
        }
      }

      .list_close_icon {
        width: 60px;
        height: 60px;
        background-color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: solid 1px $black-50;
      }
    }

    .items_scroll {
      ::v-deep(.el-scrollbar) {
        background-color: rgba(255,255,255,0.9);
      }

      ::v-deep(.el-scrollbar__bar.is-horizontal) {
        display: none;
      }

      ::v-deep(.el-scrollbar__bar.is-vertical) {
        width: 15px;
        border: 5px solid rgba(255,255,255,0.9);
        background: rgba(255,255,255,0.9);
        border-radius: 0px;
        top: 1px;
        right: 0px;
        bottom: 0px;
      }

      ::v-deep(.el-scrollbar__bar) {
        overflow: hidden;
      }

      ::v-deep(.el-scrollbar__thumb) {
        background-clip: content-box;
        background-color: $primary!important;
        border-radius: 2px;
        box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
        opacity: 1
      }
    }
  }
}

.items {
  display: flex;
  align-items: center;
  width: 300px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0);
  color: $dark-grey;
  border-bottom: solid 2px #fff;

  p {
    margin: 0 10px 0 20px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
  }
  span {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
  }
}

.items:last-child {
  margin-bottom: 0!important;
}

.items_active {
  background: $primary !important;
  opacity: 0.9 !important;
  color: #fff !important;
}
</style>
