<template>
  <div class="lecture_content">
    <icon v-if="isSp" name="bg-dot-circle" class="imgBg" />
    <lecture-header :isPc="!isSp"></lecture-header>

    <template
      v-if="viewState.isDisplayed"
    >
      <!-- エンタープライズ用  -->
      <template v-if="isEnterprise">  
        <div class="enterprize-lectures-area">
          <customCourseLecture
            v-for="(item, index) in viewState.lecturesByCustomCourse"
            :key="index"
            :customCourseLecture="item"
            @bookmark="bookmark"
          ></customCourseLecture>
        </div>
      </template>

      <!-- プレミアム・ビジネス・未ログイン -->
      <template
        v-else
      >
        <lecture-tab
          v-if="!isSp"
          :jobRoles="viewState.jobRoles"
        ></lecture-tab>
        <lectureSPTab
          v-else
          :jobRoles="viewState.jobRoles"
        ></lectureSPTab>
        
        <div :id="`jobrole-${item.id}`" :class="!isSp? 'swiper_item' : 'swiper_sp_item'"
            v-for="(item, index) in viewState.jobRoles" :key="index">
          <swiper-video
            v-if="item.id in viewState.lecturesByJobRole"
            :cid="item.id"
            :title="item.name"
            :subTitle="`(${item.lectures_count})`"
            :videos="viewState.lecturesByJobRole[item.id]"
            :levels="viewState.levels"
          ></swiper-video>
          <div class="swiper_line" v-if="index < viewState.jobRoles.length - 1"></div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import util from "@/utils/util";
import http from "@/utils/http";
import lectureHeader from "./components/lectureHeader"
import lectureTab from "./components/lectureTab"
import lectureSPTab from "./components/lectureSPTab"
import customCourseLecture from "./components/customCourseLecture"
import swiperVideo from '@/components/CommonSwiperVideo/CommonSwiperVideo'
import { defineComponent, reactive, onMounted, computed, ref, watch } from "vue";
import { useStore } from 'vuex'

export default defineComponent({
  name: "lecture",
  components: {
    lectureHeader,
    lectureTab,
    lectureSPTab,
    swiperVideo,
    customCourseLecture,
  },
  setup() {
    const store = useStore();
    const viewState = reactive({
      response: {
        status: 0,
        data: {}
      },
      isDisplayed: false,
      jobRoles: {},
      levels:  {},
      lecturesByJobRole: {},
      lecturesByCustomCourse: {}
    });
    
    window.scrollTo(0, 0);

    const isLogin = computed(() => store.state.user.isLogin);
    const isSp = computed(() => store.state.isSp);
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const hashId = ref(0);

    // method
    const fetchJobRoles = async () => {
      return http
        .get("/api/job-roles")
        .then((response) => {
          // 講座が紐付いているジョブロールのみ対象とする
          const jobRoles = response.data.filter(jobRole => jobRole.lectures_count > 0);
          viewState.jobRoles = util.keyBy(jobRoles, "id");
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchLevels = () => {
      return http
        .get("/api/levels")
        .then((response) => {
          viewState.levels = util.keyBy(response.data, "id");
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchLecturesByJobRole = async (jobRoleId) => {
      return await http
        .get(`/api/lectures/?job_role=${jobRoleId}&count=80`)
        .then((response) => {
          console.dir(response.data);
          const lectures = response.data;
          viewState.lecturesByJobRole = { ...viewState.lecturesByJobRole, [jobRoleId]: lectures };

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    }
    const fetchLecturesByCustomCourse = async () => {
      return await http
        .get(`/api/custom-course-lectures`)
        .then((response) => {
          console.dir(response.data);
          viewState.lecturesByCustomCourse = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    }
    const bookmark = (customCourseKey, lectureKey, isFavorite) => {
      viewState.lecturesByCustomCourse.forEach((customCourse) => {
        if (customCourse.custom_course_key == customCourseKey) {
          customCourse.lectures.forEach((lecture) => {
            if (lecture.key == lectureKey) {
              lecture.is_favorite = isFavorite;
            }
          });
        }
      });
    }
    const init = async () => {
      if (isEnterprise.value) {
        await fetchLecturesByCustomCourse();
        viewState.isDisplayed = true;
      } else {
        // レベル一覧取得
        fetchLevels();
        
        if(location.hash) {
          hashId.value = location.hash.slice(1);
        }

        await fetchJobRoles();
        viewState.isDisplayed = true;

        // ジョブロールごとの講座取得
        await Promise.all(
          Object.keys(viewState.jobRoles).map(async jobRoleId => {
            await fetchLecturesByJobRole(jobRoleId);
          })
        );
      }
    };
    const reInit = async () => {
      console.log("re-init");
      await init();
    };

    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで講座情報を取得する
        console.log("skip on-mount process");
        return;
      }
      
      await init();
    });

    watch(
      () => viewState.lecturesByJobRole,
      () => {
        if (hashId.value > 0 && hashId.value in viewState.lecturesByJobRole) {
          const element = document.getElementById(`jobrole-${hashId.value}`);
          if (element) {
            $("html, body").animate(
              {
                scrollTop: element.offsetTop,
              },
              500
            );
          }
        }
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );

    return {
      util,
      viewState,
      isLogin,
      isSp,
      isEnterprise,
      bookmark,
    };
  },
});
</script>

<style lang="scss" scoped>
.lecture_content {
  max-width: 1366px;
  position: relative;
  margin: 0 auto 115px;
  @include mq(sm) {
    margin-bottom: 80px;
  }

  .untitled_half {
    position: absolute;
    top: 1001px;
    left: -1px;
    transform: rotate(1.36deg);
    z-index: -1;
  }

  .imgBg {
    position: absolute;
    width: 282px;
    height: 282px;
    top: 446px;
    left: -140px;
    z-index: -1;
  }

  .swiper_item {
    padding-top: 65px;
    padding-left: 50px;

    .swiper_line {
      width: 1136px;
      height: 0.5px;
      background-color: $black-50;
      margin-left: 65px;
      margin-top: 65px;
    }

    ::v-deep(.common-titles-title) {
      white-space: normal !important;
      margin-right: 20px!important;
      text-align: left!important;
    }

    ::v-deep(.common-titles-sub-title) {
      width: 77px!important;
      font-weight: bold!important;
      font-size: 20px!important;
      display: flex!important;
      align-items: center!important;
      text-align: center!important;
      letter-spacing: 0.08em!important;
      color: $secondary!important;
      flex: none!important;
      order: 1!important;
      flex-grow: 0!important;
      opacity: 1!important;
    }

    ::v-deep(.common-title-area) {
      height: 70px;
      margin-right: 205px;
    }

    ::v-deep(.common-title::before) {
      top: 4px !important;
    }

  }

  .swiper_sp_item {
    width: 100%;
    padding-top: 60px;
    padding-left: 20px;

    .swiper_line {
      width: 275px;
      height: 0.5px;
      background-color: $black-50;
      margin-left: 30px;
    }

    ::v-deep(.common-title::before) {
      top: 15px !important;
    }

    ::v-deep(.common-titles-sub-title) {
      display: none !important;
    }
  }
  

  .enterprize-lectures-area {
    padding-left: 50px;
    max-width: 1366px;
    width: 100%;
    @include mq(sm) {
      padding-left: 20px;
    }
  }
  .enterprize-lectures {
    margin-top: 65px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1349px;
    @include mq(sm) {
      margin-top: 60px;
      width: 335px;
    }

    .common-title {
      &::before {
        top: 0px;
      }
      ::v-deep(.common-title-area) {
        @include mq(sm) {
          height: unset;
        }
        .common-titles-title {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 40px;
          line-height: 60px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $black;
          @include mq(sm) {
            margin-top: 0px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 0.08em;
            color: $black;
            text-align: left;
            margin-right: 50px;
          }
        }
        .common-titles-sub-title {
          width: 500px;
          display: flex;
          justify-content: flex-start;
          line-height: unset;
          @include mq(sm) {
            line-height: 100%;
          }
        }
      }
    }
  }

  .flex-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 65px;
    margin-right: 65px;
    width: 100%;

    @include mq(sm) {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 30px;
      width: 275px;
      height: auto;
      .flex-video-items {
        height: 418px;
      }
    }

    .flex-video-item {
      margin-right: 28px;
    }
  }
}
</style>
